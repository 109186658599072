import React from "react";
import "./portfolio.css";

import bookStoreImg from "../../assets/portfolio3.webp";
import weaterAppImg from "../../assets/portfolio2.png";
import chatAppImg from "../../assets/portfolio7.png";
import ecommerceImg from "../../assets/portfolio6.jpg";
import nutrixAppImg from "../../assets/portfolio8.jpg";
import imsAppImg from "../../assets/portfolio9.jpg";
import cruiseFinder from "../../assets/portfolio16.jpg";
import tinderClone from "../../assets/portfolio17.png";


const data = [
  {
    id: 1,
    image: bookStoreImg,
    techStack: "MERN Stack",
    title: "Book Store",
    github: "https://github.com/xjohnfit/MERN-Bookstore/",
    demo: "https://mern-bookstore-3ppu.onrender.com/",
  },
  {
    id: 2,
    image: weaterAppImg,
    techStack: "MERN Stack",
    title: "Weather App",
    github: "https://github.com/xjohnfit/MERN-Weather-App2",
    demo: "https://weatherapp.codewithxjohn.com/",
  },
  {
    id: 3,
    image: chatAppImg,
    techStack: "MERN Stack",
    title: "Realtime Chat App",
    github: "https://github.com/xjohnfit/Realtime-Chat",
    demo: "https://realtime-chat-hb7q.onrender.com/login",
  },
  {
    id: 4,
    image: ecommerceImg,
    techStack: "MERN Stack",
    title: "E-commerce Store - Updating code.",
    github: "#",
    demo: "#",
  },
  {
    id: 5,
    image: nutrixAppImg,
    techStack: "MERN Stack",
    title: "Nutrition App - Updating code.",
    github: "#",
    demo: "#",
  },
  {
    id: 6,
    image: imsAppImg,
    techStack: "Full Stack PHP and MySQL",
    title: "Inventory Management System - Updating code",
    github: "#",
    demo: "#",
  },
  {
    id: 7,
    image: cruiseFinder,
    techStack: "MERN Stack",
    title: "Real Time Cruise Finder and Information",
    github: "https://github.com/xjohnfit/MERN-CruiseFinder",
    demo: "https://cruisefinder.codewithxjohn.com/",
  },
  {
    id: 8,
    image: tinderClone,
    techStack: "MERN Stack",
    title: "Dating App - Tinder Clone",
    github: "https://github.com/xjohnfit/MERN-Tinder-Clone/",
    demo: "https://mern-tinder-clone-nq45.onrender.com",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>Personal Projects</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, techStack, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{ techStack }</h3>
              <h4>{ title }</h4>
              <div className="portfolio__item-cta">
                <a href={github} className="btn" target="_blank" rel="noreferrer">
                  GitHub
                </a>
                <a
                  href={demo}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
