import React from "react";
import "./services.css";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Frontend Development</h3>
          </div>

          <ul className="service__list">
            <li>
              <p>
                Frontend developers focus on creating the user interface (UI)
                and user experience (UX) of a website or web application. They
                use languages like HTML, CSS, and JavaScript along with
                frameworks/libraries like React, Angular, or Vue.js to build
                interactive and responsive interfaces.
              </p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className="service__head">
            <h3>Backend Development</h3>
          </div>

          <ul className="service__list">
            <li>
              <p>
                Backend developers work on the server-side of web applications.
                They write code using languages such as Python, Ruby, Java,
                Node.js, or PHP to handle data processing, database
                interactions, user authentication, and server management. They
                also create APIs (Application Programming Interfaces) that allow
                the frontend and backend to communicate.
              </p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Database Management</h3>
          </div>
          <ul className="service__list">
            <li>
              <p>
                Web development services often include database management
                tasks. This involves designing, implementing, and maintaining
                databases using technologies like MySQL, PostgreSQL, MongoDB, or
                Redis. Database management ensures efficient data storage,
                retrieval, and management for web applications.
              </p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Responsive Design</h3>
          </div>
          <ul className="service__list">
            <li>
              <p>
                Web developers ensure that websites and web applications are
                responsive, meaning they adapt and display correctly on various
                devices and screen sizes (desktops, laptops, tablets,
                smartphones). This involves using responsive design techniques
                and testing across different devices and browsers.
              </p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>E-commerce Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <p>
                For businesses looking to sell products or services online, web
                development services can include e-commerce development. This
                involves integrating payment gateways, shopping carts, inventory
                management systems, and security features to create secure and
                user-friendly online stores.
              </p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Content Management Systems (CMS)</h3>
          </div>
          <ul className="service__list">
            <li>
              <p>
                Web developers may work with CMS platforms like WordPress,
                Drupal, or Joomla to build websites that clients can easily
                update and manage without deep technical knowledge. They
                customize themes, plugins, and functionalities to meet specific
                requirements.
              </p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Search Engine Optimization (SEO)</h3>
          </div>
          <ul className="service__list">
            <li>
              <p>
                Web development services often include SEO optimization to
                improve a website's visibility and ranking on search engines
                like Google. This includes optimizing content, meta tags, page
                speed, mobile-friendliness, and implementing SEO best practices.
              </p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Web Hosting and Maintenance</h3>
          </div>
          <ul className="service__list">
            <li>
              <p>
                Web developers assist with web hosting setup, deployment, and
                ongoing maintenance. They ensure that websites are hosted on
                reliable servers, perform regular backups, monitor performance,
                and apply updates and security patches as needed.
              </p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
