import React from 'react';
import './header.css';
import CTA from './CTA';
import me from '../../assets/me.png';
import HeaderSocial from './HeaderSocials';
import TypeIt from 'typeit-react';

const Header = () => {
    return (
        <header>
            <div className="container header__container">
                <HeaderSocial />
                <div className="me">
                    <TypeIt as={'h1'} options={{speed: 150, waitUntilVisible: true}}
                    getBeforeInit={(instance) => {
                      instance.type("Hi, I'm Jobn").pause(750).delete(2).pause(500).type("hn. ").pause(750).type("A Full Stack Developer.");
                  
                      // Remember to return it!
                      return instance;
                    }}
                    />
                    <img
                        className="header_img"
                        src={me}
                        alt="Headshot"
                    />
                    <CTA />
                </div>
            </div>
        </header>
    );
};

export default Header;
